import React from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { startRegistroPuesto } from '../../../../../app/store/catalagos/thunks';

export const NewPuesto = () => {
    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            nombrePuesto: "",
            salarioPuesto: "",
            descripcionPuesto: "",
        }
    });

    const { usuario } = useSelector(state => state.usuario);
    const { status, isLoading } = useSelector(state => state.catalogos);

    const dispatch = useDispatch();
    const closeModal = useRef(null);
    const handleSavePuesto = (data) => {
        let usuario_cap = usuario;
        dispatch(startRegistroPuesto(data, usuario_cap));
    }

    const clearForm = () => {
        reset();
    }


    useEffect(() => {
        if (status == 'Puestos Obtenidos') {
            reset();
        }
    }, [status])


    if (status == 'Puestos Obtenidos') closeModal.current.click();

    return (
        <div className="modal fade" id="newPuesto" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo Puesto</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handleSavePuesto)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-7">
                                    <div className="mb-2">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombrePuesto", { required: 'Nombre del puesto es requerido.', minLength: { value: 5, message: 'El nombre debe contener minimo 5 caracteres' } })} />
                                        {
                                            errors.nombrePuesto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombrePuesto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-5">
                                    <div className="mb-2">
                                        <label className="form-label">Salario:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("salarioPuesto", { required: 'Salario es requerido.' })} />
                                        {
                                            errors.salarioPuesto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.salarioPuesto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Descripción:</label>
                                        <textarea className="form-control" rows="6" autoComplete='off'
                                            {...register('descripcionPuesto', {
                                                required: 'La descripción del Puesto es requerido.',
                                                minLength: { value: 10, message: 'La Descripción debe contener por los menos 10 caracteres' },
                                                maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                            })}
                                        ></textarea>
                                        {
                                            errors.descripcionPuesto &&
                                            <div className='text-left-20 mt-2'>
                                                <AlertTriangle size="18" className='text-danger' />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionPuesto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" /> Cancelar
                            </button>
                            {
                                status == "PROCESANDO_PETICION" ?
                                    <>  <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Guardando...
                                    </button></> :
                                    <>
                                        <button type="submit" className="btn btn-outline-primary"  >
                                            <Save size="20" /> Guardar
                                        </button>
                                    </>
                            }
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
