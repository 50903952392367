import React, { useRef } from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startAddRefacciones } from '../../../../app/store/ordenes/thunks';

export const NewPart = () => {
    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            refaccion: "",
            costoHDL: "",
            costoCliente: "",
            enlace: "",

        }
    });
    const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const closeModal = useRef(null);
    const dispatch = useDispatch();


    const saveRefaccion = (data) => {
        let folio = orden_edit[0].FOLIO;
        dispatch(startAddRefacciones(folio, data, id_emp));
        closeModal.current.click();
    }


    return (
        <div className="modal fade" id="newPart" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Solicitud de Refacción</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit(saveRefaccion)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Refaccion:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("refaccion", { required: 'Nombre de Refaccion, es requerido' })} />
                                        {
                                            errors.refaccion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.refaccion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo HDL($):</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...register("costoHDL", { required: 'Costo refaccion, es requerido' })} />
                                        {
                                            errors.costoHDL &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoHDL.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo Cliente($):</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...register("costoCliente", { required: 'Costo refaccion, es requerido' })} />
                                        {
                                            errors.costoCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <div className="col-4">
                                <div className="mb-2">
                                    <label className="form-label">Costo Total($):</label>
                                    <input type="text" className="form-control" aria-label="First name"/>
                                </div>
                            </div> */}

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Enlace:</label>
                                        <textarea className="form-control" rows="8" {...register("enlace", { required: 'Enlace, para compra de refaccion' })}></textarea>
                                        {
                                            errors.enlace &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.enlace.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                                <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary" >
                                <Save size='20' /> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
