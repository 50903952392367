import React, { useEffect } from 'react'
import { startloadEmpleados } from '../../../../app/store/empleados/thunks'
import { AlertTriangle, ArrowLeft, RefreshCcw } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { startEditEmpleado } from '../../../../app/store/empleados/thunks';
import { Link } from 'react-router-dom';
import { startloadOrdenesEmpleados } from '../../../../app/store/ordenes/thunks';

export const EditEmployee = () => {
    const { empleado_edit, isLoading, status } = useSelector(state => state.empleados);
    const { ordenes, status_ordenes } = useSelector(state => state.ordenes);
    const { puestos, roles } = useSelector(state => state.catalogos);
    const dispatch = useDispatch();

    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }

    // console.log(empleado_edit);

    const goback = () => {
        dispatch(startloadEmpleados());
    }


    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            nombreEmpleado: "",
            rfcEmpleado: "",
            emailEmpleado: "",
            telEmpleado: "",
            celEmpleado: "",
            puestoEmpleado: "",
            rolEmpleado: "",
            usuario: "",
            password: "",
            estadoEmpleado: "",
        }
    });


    const generatePWF = () => {
        const alph = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890!@#";
        let pwdgenerated = "";
        for (let index = 0; index <= 10; index++) {
            pwdgenerated += alph.charAt(Math.floor(Math.random() * alph.length))
        }
        setValue('password', pwdgenerated);
        // console.log(pwdgenerated);
    }


    useEffect(() => {
        if (status == 'EMPLEADO_ENCONTRADO') {
            setValue('nombreEmpleado', empleado_edit.NOMBRE);
            setValue('usuario', empleado_edit.USER);
            setValue('rfcEmpleado', empleado_edit.RFC);
            setValue('emailEmpleado', empleado_edit.EMAIL);
            setValue('telEmpleado', empleado_edit.TELEFONO);
            setValue('celEmpleado', empleado_edit.CELULAR);
            setValue('puestoEmpleado', empleado_edit.PUESTO_ID);
            setValue('rolEmpleado', empleado_edit.ROL_ID);
            setValue('estadoEmpleado', empleado_edit.ESTADO);
            dispatch(startloadOrdenesEmpleados(empleado_edit.ID))
        }
    }, [status])

    const clearForm = () => {
        reset();
    }

    const handleSaveEmpleado = (data) => {
        dispatch(startEditEmpleado(data, empleado_edit.ID, empleado_edit.ACCESO_ID));
        clearForm();
    }

    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
                        <li className="breadcrumb-item active" aria-current="page">Detalles del Empleado</li>
                    </ol>
                </nav>
            </div>

            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                <div className="card">

                    <div className="card-body">
                        <div className="row mb-1">
                            <div className="col-12">
                                <h5 className="fw-bold me-2 border-0">Datos del Empleado: {empleado_edit.ID}  </h5>
                            </div>
                        </div>
                        <hr className="my-0" />

                        <form onSubmit={handleSubmit(handleSaveEmpleado)}>
                            <div className="row mt-3">
                                <div className='col-12'>
                                    <div className="mb-2">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreEmpleado", { required: 'Nombre del empleado es requerido.' })} />
                                        {
                                            errors.nombreEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">Usuario:</label>
                                        <input type="text" className="form-control" aria-label="First name"  {...register("usuario", { required: 'Usuario es requerido.' })} disabled />
                                        {
                                            errors.usuario &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.usuario.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">RFC:</label>
                                        <input type="text" className="form-control" aria-label="First name"   {...register("rfcEmpleado", {
                                            required: 'RFC requerido.',
                                            minLength: { value: 12, message: 'RFC minimo 12 caracteres, sin guiones ni espacios' },
                                            maxLength: { value: 13, message: 'RFC maximo 13 caracteres, sin guiones ni espacios' }
                                        })} />

                                        {
                                            errors.rfcEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rfcEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className='col-12'>
                                    <div className="mb-2">
                                        <label className="form-label">Correo Electronico:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("emailEmpleado", {
                                            required: 'Email es requerido.',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Introduce una direccion de correo',
                                            },
                                        })} />
                                        {
                                            errors.emailEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.emailEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("telEmpleado")} />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">Celular:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("celEmpleado", { required: 'Celular es requerido.' })} />
                                        {
                                            errors.celEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.celEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">Puesto:</label>
                                        <select className="form-select" {...register('puestoEmpleado', { required: 'Debe seleccionar el Puesto' })} >
                                            <option value="">Seleccione un puesto</option>
                                            {
                                                puestos.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.puestoEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.puestoEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="mb-2">
                                        <label className="form-label">Rol:</label>
                                        <select className="form-select" {...register('rolEmpleado', { required: 'Debe seleccionar el Rol' })}>
                                            <option value="">Seleccione un rol</option>
                                            {
                                                roles.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.rolEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rolEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className='col-12 mt-1'>
                                    <label className="form-label">Nueva contraseña:</label>
                                    <div className=" mb-2">
                                        <input className="form-control me-2" type="text" placeholder="" aria-label="Search"  {...register("password")} />
                                        {
                                            errors.password &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.password.message}</small></span>
                                            </div>
                                        }
                                        <button className="btn btn-outline-success mt-2" type="button" onClick={generatePWF}>Generar</button>
                                    </div>
                                </div>

                                <div className='col-12 mb-2'>
                                    <label className="form-label">Estado:</label>
                                    <select className="form-select" {...register('estadoEmpleado', { required: 'Debe seleccionar un estado' })}>
                                        <option value="">Seleccione una Opcion</option>
                                        <option value="1">Activo</option>
                                        <option value="2">Eliminado</option>
                                        <option value="3">Vacaciones</option>
                                    </select>
                                    {
                                        errors.estadoEmpleado &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.estadoEmpleado.message}</small></span>
                                        </div>
                                    }
                                </div>


                                <div className="col-12 text-end mt-3">
                                    <button type="submit" className="btn btn-outline-primary ms-2" >
                                        <RefreshCcw size='20' />  Actualizar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                <div className="card">
                    <div className="card-body">
                        <div className="row mb-1">
                            <div className="col-12">
                                <h5 className="fw-bold me-2 border-0">Listado de Ordenes de Servicio. </h5>
                            </div>
                        </div>
                        <hr className="my-0" />
                        <div className="table-responsive p-2 mt-1 table-scrollbar">
                            <table className="table table-striped table-hover table-sm my-0">
                                <thead>
                                    <tr>
                                        <th scope="col" className='text-start th-sticky'>FOLIO</th>
                                        <th scope="col" className="text-start th-sticky">TECNICO</th>
                                        <th scope="col" className="text-start th-sticky">F.RECIBIDO</th>
                                        <th scope="col" className="text-start th-sticky">F.ASIGNACION</th>
                                        <th scope="col" className="text-start th-sticky">F.DIAGNOSTICO</th>
                                        <th scope="col" className="text-center th-sticky">ESTADO</th>
                                        {/* <th scope="col" className="text-center">ACCIONES</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        ordenes.length === 0 ?
                                            <>
                                                <tr>
                                                    <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                </tr>
                                            </> : ordenes.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">
                                                        <Link to={`/trackingOrder/${item.FOLIO}`}>
                                                            {item.FOLIO}
                                                        </Link>

                                                    </td>
                                                    <td className="text-center">{item.TECNICO_ASIGNADO}</td>
                                                    <td className="text-center">{item.F_RECIBIDO}</td>
                                                    <td className="text-center">{item.F_ASIGNACION}</td>
                                                    <td className="text-center">{item.F_DIAGNOSTICO}</td>
                                                    <td className="text-center">
                                                        {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Sin Asignar </span>  </> : <> </>}
                                                        {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Asignada </span>  </> : <> </>}
                                                        {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Sin Diagnostico </span>  </> : <> </>}
                                                        {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Con Diagnostico </span>  </> : <> </>}
                                                        {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> En espera de Componente </span>  </> : <> </>}
                                                        {item.ESTADO == 5 ? <> <span className="status-btn esperacom-btn"> Completa / Terminada </span>  </> : <> </>}
                                                        {item.ESTADO == 6 ? <> <span className="status-btn complete-btn"> No Aprobada a Entrega </span>  </> : <> </>}
                                                        {item.ESTADO == 7 ? <> <span className="status-btn complete-btn"> Aprobada a Entrega </span>  </> : <> </>}
                                                        {item.ESTADO == 8 ? <> <span className="status-btn complete-btn"> Entregada a Cliente </span>  </> : <> </>}
                                                        {item.ESTADO == 9 ? <> <span className="status-btn pending-btn"> Cancelado </span>  </> : <> </>}

                                                        {/* {item.ESTADO} */}
                                                        {/* <span className="status-btn complete-btn text-uppercase"> COMPLETA </span> */}
                                                    </td>
                                                </tr>
                                            ))
                                    }


                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
