import React, { useEffect } from 'react';
import { Slash, Save, AlertTriangle, Clock, CheckCircle, Link as LINKIMG, Loader, Search, PlusSquare, PlusCircle, MinusCircle, Percent, Box, File, FilePlus, FileText, FileMinus, AlertCircle, Check, CheckSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { startloadOrdenesDash } from '../../../../app/store/ordenes/thunks';
import { Link, Navigate } from 'react-router-dom';

export const Dash_Tecnico = () => {

    const { ordenes, indicadores, isLoading } = useSelector(state => state.ordenes);
    const { usuario, nombre, id_emp } = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    useEffect(() => {
        let data = { tecnico: id_emp, estadoOrden: 'TODOS' }
        dispatch(startloadOrdenesDash(data));
    }, [])

    return (
        <>
            <div className="row">
                <div className="mb-3">
                    <h1 className="h4 d-inline align-middle fw-bold">Indicadores para: <span className="text-muted "> {nombre}</span> </h1>
                </div>
               
            </div>

            <div className="row">
               

                <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-dark">Asignadas</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <CheckSquare size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.ASIGNADAS}</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-dark">Sin diagnostico</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <AlertCircle size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.NO_DIAGNOSTICO}</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="card-title text-dark">Con diagnostico</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <Check size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.CON_DIAGNOSTICO}</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-dark">En espera de piezas</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <Clock size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.ESPERA_COMPONENTE}</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-dark">Terminadas</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <Check size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.TERMINADA}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-dark">Aprobada Entrega</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <CheckCircle size='20' />
                                    </div>
                                </div>
                            </div>
                            <div className='text-center'>
                                <h1 className="mt-1 mb-3">{indicadores.AUTORIZADAS}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="mb-1">
                    <div className="mb-3">
                        <h1 className="h4 d-inline align-middle fw-bold">Listado de Ordenes</h1>
                    </div>

                    <div className="card">
                        <div className="card-body ">


                            <div className="table-responsive my-0 table-scrollbar-2">

                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col" className="text-center">FOLIO</th>
                                            <th scope="col" className="text-center">PREMIUM</th>
                                            <th scope="col" className="text-center">F.RECIBIDO</th>
                                            <th scope="col" className="text-center">F.ASIGNACIÓN</th>
                                            <th scope="col" className="text-center">F.DIAGNOSTICO</th>
                                            {/* <th scope="col" className="text-center">F.AUTORIZADA</th> */}
                                            <th scope="col" className="text-center">ESTADO</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {
                                            ordenes.length == 0 ?
                                                <>
                                                    <tr>
                                                        <td colSpan={8}>No se encontraron Ordenes de Servicio</td>
                                                    </tr>
                                                </> :

                                                ordenes.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className="text-center">
                                                            <Link to={`/trackingOrder/${item.FOLIO}`}>
                                                                {item.FOLIO}
                                                            </Link>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                item.PREMIUM == 1 ?
                                                                    <> <img src="./assets/images/indicators/star.png" width="24" height="24" /></> :
                                                                    <></>
                                                            }
                                                        </td>

                                                        <td className="text-center">
                                                            {item.FECHA_FORMATEADA}
                                                        </td>
                                                        <td className="text-center">{item.FECHA_ASIGNACION}</td>
                                                        <td className="text-center">{item.FECHA_DIAGNOSTICO}</td>
                                                        {/* <td className="text-center">{item.FECHA_}</td> */}
                                                        <td className="text-center">
                                                            {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Sin Asignar </span>  </> : <> </>}
                                                            {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Asignada </span>  </> : <> </>}
                                                            {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Sin Diagnostico </span>  </> : <> </>}
                                                            {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Con Diagnostico </span>  </> : <> </>}
                                                            {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> En espera de Componente </span>  </> : <> </>}
                                                            {item.ESTADO == 5 ? <> <span className="status-btn esperacom-btn"> Completa / Terminada </span>  </> : <> </>}
                                                            {item.ESTADO == 6 ? <> <span className="status-btn complete-btn"> No Aprobada a Entrega </span>  </> : <> </>}
                                                            {item.ESTADO == 7 ? <> <span className="status-btn complete-btn"> Aprobada a Entrega </span>  </> : <> </>}

                                                        </td>

                                                    </tr>
                                                ))

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
