import React, { useEffect } from 'react'
import { ArrowLeft, Plus, Printer, Save, Search, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import { clearStoreCorts, startRegistroCorte, startloadCorte, startloadCortes } from '../../../../app/store/finanzas/thunk';
import { useDispatch, useSelector } from 'react-redux';

export const Corte = () => {

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' });
    const { indicadores, cortes_reg, descuentos, status, isLoading } = useSelector(state => state.finanzas);
    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;
    const dispatch = useDispatch();
    const searchOrdenes = (data) => {
        dispatch(startloadCortes(data.fechaInicial, data.fechaFinal))
    }

    let total = 0;
    cortes_reg.forEach(item => {
        total = total + Number(item.TOTAL_INGRESOS)
    });



    const generateRep = ($id) => {
        window.open(`./assets/reports/reporteCorte.html?id=${$id}`, '_blank');
    }

    const cleanCort = () => {
        dispatch(clearStoreCorts())
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link to={`/finance`} className='breadcrumb-item btn-link' onClick={cleanCort}>
                                <ArrowLeft size='20' /> Atras
                            </Link>
                            <li className="breadcrumb-item active" aria-current="page">Cortes de Caja</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='row'>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom">
                            <h4 className=" text-black fw-bold mb-0">Cortes de caja.</h4>
                            <div>
                                <Link to={`/finance/newCort`} className="btn btn-outline-primary fw-bold ">
                                    <Plus size='20' />  Nuevo Corte
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(searchOrdenes)} className='border-bottom'>
                                <div className="row ">
                                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <div className="mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">Fecha Inicio:</label>
                                            <input type='date' className='form-control'  {...register("fechaInicial")} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <div className="mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">Fecha Final:</label>
                                            <input type='date' className='form-control' defaultValue={today} {...register("fechaFinal")} />
                                        </div>
                                    </div>

                                    <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                                        <div className="mb-3 mt-4">
                                            <button type="submit" className="btn btn-outline-secondary">
                                                <Search size="20" /> Buscar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            {
                                (isLoading) ?
                                    <>
                                        <div className='div_center'>
                                            <div className='text-center'>
                                                <div className="spinner"></div>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <span className='fw-bold'>Cargando</span>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <div className="spinner"></div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className="table-responsive my-0 table-scrollbar-2 mt-3">
                                            <table className="table table-striped table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-center th-sticky">#</th>
                                                        <th scope="col" className="text-center th-sticky">CAPTURO</th>
                                                        <th scope="col" className="text-center th-sticky">FECHA REG.</th>
                                                        <th scope="col" className="text-center th-sticky">HORA</th>
                                                        <th scope="col" className="text-end th-sticky">MONTO TOTAL</th>
                                                        <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                                        {/* <th scope="col" className="text-center th-sticky">DESCRIPCION</th> */}
                                                        {/* <th scope="col" className="text-center th-sticky">APLICADO</th> */}

                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        cortes_reg?.length == 0 ?
                                                            <>
                                                                <tr>
                                                                    <td colSpan={7}>No se encontraron descuentos</td>
                                                                </tr>
                                                            </> :
                                                            cortes_reg?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className='text-center'>{item.ID}</td>
                                                                    <td className="text-center">
                                                                        {item.CAPTURO}
                                                                    </td>
                                                                    <td className="text-center">{item.FECHA.split("-").reverse().join("-")}</td>
                                                                    <td className="text-center">{item.HORA}</td>
                                                                    {/* <td className="text-center">{item.DETALLE}</td> */}
                                                                    <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL_INGRESOS)}</td>
                                                                    <td className="text-center">
                                                                        <button type="button" className="btn btn-outline-dark" onClick={() => generateRep(item.ID)}>
                                                                            <Printer size={20} />
                                                                        </button>
                                                                    </td>

                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={4} className='text-end fw-bold'>TOTAL:</td>
                                                        <td className='text-end fw-bold'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((total).toFixed(2)
                                                        )}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </>
                            }
                        </div>
                    </div>
                </div>


            </div>













        </>
    )
}
