import React from 'react'
import { Catalogos } from './panels/Catalogos'
import { Principales } from './panels/Principales'

export const Global = () => {
  const solo_num = (e) => {
    if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
    else e.preventDefault();
  }

  return (
    <>
      <Principales solo_num={solo_num} />
      <Catalogos solo_num={solo_num}/>
    </>
  )
}
