import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { connectionFiles } from '../../../app/filesConnection'
import { startChangeStateFacturacion, startloadSolFacturacion } from '../../../app/store/reportes/thunks';
import { Link } from 'react-router-dom';
import { Eye } from 'react-feather';
import Swal from 'sweetalert2';


export const Invoicing = () => {

    const { isLoading, status_report, facturacion } = useSelector(state => state.reportes);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startloadSolFacturacion())
    }, []);

    const handleChangeState = (e, row) => {
        console.log(e);
        console.log(row);
        // console.log(FOLIO);

        let tipo = row.FOLIO.includes('HDLV') == true ? 2 : 1;
        let folio = row.FOLIO;
        // let id = row.ID;
        let estado = e.target.value;

        console.log(tipo);
        console.log(folio);
        // console.log(id);
        console.log(estado);



        Swal.fire({
            title: 'Atención',
            text: "Está por cambiar de Estado a la Solicitud de Facturación, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startChangeStateFacturacion(tipo, folio, estado));
            } else {
                  dispatch(startloadSolFacturacion());
            }
        })






    }


    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <h3 className="h3 mb-3 fw-bold">Facturación</h3>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header border-bottom">
                            <h5 className="card-title mb-0">Listado...</h5>
                        </div>
                        <div className="card-body">


                            <div className="row">
                                <div className="table-responsive table-responsive p-2 mt-2 table-scrollbar-3 mb-4">
                                    <table className="table tbale-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">FOLIO</th>
                                                <th scope="col" className="text-center">FECHA SOL.</th>
                                                <th scope="col" className="text-start">CLIENTE</th>
                                                <th scope="col" className="text-start">DATOS FACTURACIÓN</th>
                                                <th scope="col" className="text-center">CSF</th>
                                                <th scope="col" className="text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                facturacion.length == 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan={7}>No hay facturas pendientes de emitir</td>
                                                        </tr>
                                                    </> :
                                                    facturacion.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>
                                                                {
                                                                    item.FOLIO.includes('HDLV') ?
                                                                        <> <a type="button" href={`./assets/reports/ordenVenta.html?folio=${item.FOLIO}`} target='_blank' className="btn btn-link border-0">  {item.FOLIO} </a> </> :
                                                                        <><a type="button" href={`./assets/reports/ordenEntrega.html?folio=${item.FOLIO}`} target='_blank' className="btn btn-link border-0">  {item.FOLIO} </a></>
                                                                }
                                                            </td>
                                                            <td>{item.FECHA}</td>
                                                            <td>{item.CLIENTE}</td>
                                                            <td className="text-start" >
                                                                RAZÓN SOC: {item.RAZON_SOCIAL}, REGIMEN: {item.REGIMEN_FISCAL}, SOC. MERCAN: {item.SOCIEDAD_MERCANTIL},
                                                                EMAIL: {item.CORREO}, DIRECCION: {item.DIRECCION}, {item.COD_POSTAL}, TEL: {item.TELEFONO}
                                                            </td>
                                                            <td>
                                                                <a type="button" href={`${connectionFiles['csfURL']}/${item.CSF}`} target='_blank' className="btn btn-outline-dark border-0">  <Eye size="20" /></a>
                                                            </td>
                                                            <td className="text-center" style={{ width: 130 }}>
                                                                <select className="form-select" defaultValue={0} onChange={e => handleChangeState(e, item)}>
                                                                    {/* <option value={0}>Seleccion</option> */}
                                                                    <option value={0}>Pendiente</option>
                                                                    <option value={1}>Realizado</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
