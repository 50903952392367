import React, { useEffect } from 'react'

export const Report = () => {

  
  return (
    <>
    Report
    </>
  )
}
