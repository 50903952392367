import React from 'react'
import { Plus, RefreshCcw, Search } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { searchProductos, startloadProductos } from '../../../../app/store/catalagos/thunks';
import Swal from 'sweetalert2';
import { useState } from 'react';

export const SearchProd = ({ handledAddProduct }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const dispatch = useDispatch();
    const { categoriasAlm, marcasAlm, proveedoresAlm, productosAlm, productosAlmEdit, isLoading } = useSelector(state => state.catalogos);

    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            textSearch: "",
            param: "1"
        }
    });

    const handledSearch = (data) => {
        dispatch(searchProductos(data));
    }


    const handledsearchGeneral = () => {
        reset();
        dispatch(startloadProductos());
    }


    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }

    // console.log(ProductosCompra);

    return (
        <>
            <div className="row">

                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                <form onSubmit={handleSubmit(handledSearch)}>
                                    {/*  */}
                                    <div className='mb-2'>
                                        <label className="mb-1 text-dark">Buscar por:</label>
                                        <div className="d-flex">
                                            <select className="form-select w-75 me-1" {...register("param")} defaultValue={3} >
                                                <option value='1'>CODIGO BARRA</option>
                                                <option value='2'>NOMBRE</option>
                                                <option value='3'>DESCRIPCION</option>
                                            </select>

                                            <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                                            <button className="btn btn-outline-secondary" type="submit"  >
                                                <Search size="20" />
                                            </button>
                                            <button className="btn btn-outline-success ms-2" type="button" onClick={handledsearchGeneral} >
                                                <RefreshCcw size="20" />
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>

                            <div className="table-responsive p-2 mt-2 table-scrollbar-7">
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">SKU</th>
                                            <th scope="col" className="text-start">NOMBRE</th>
                                            <th scope="col" className="text-start">DESCRIPCIÓN</th>
                                            <th scope="col" className="text-start">MODELO</th>
                                            <th scope="col" className="text-center">EXISTENCIA</th>
                                            {/* <th scope="col" className="text-center">EXIS. MIN.</th> */}
                                            {/* <th scope="col" className="text-center">EXIS. MAX.</th> */}
                                            {/* <th scope="col" className="text-end">COSTO</th> */}
                                            <th scope="col" className="text-end">COSTO PUB.</th>
                                            <th scope="col" className="text-center">ESTADO</th>
                                            <th scope="col" className="text-center">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productosAlm.length === 0 ?
                                                <>
                                                    <tr>
                                                        <td scope="row" colSpan={10}> No se han encontrado datos.</td>
                                                    </tr>
                                                </> :
                                                productosAlm.map((item, index) => (
                                                    <tr key={index} className={item.saldo <= 0 ? 'table-warning' : ''}>
                                                        <td scope="row">{item.ID}</td>
                                                        <td className="text-start">{item.NOMBRE}</td>
                                                        <td className="text-start">{item.DESCRIPCION}</td>
                                                        <td className="text-start">{item.MODELO}</td>
                                                        <td className="text-center">{item.STOCK}</td>
                                                        {/* <td className="text-center">{item.EXISTENCIA_MIN}</td> */}
                                                        {/* <td className="text-center">{item.EXISTENCIA_MAX}</td> */}
                                                        {/* <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td> */}
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_PUBLICO)}</td>
                                                        <td className="text-center">
                                                            {
                                                                item.ESTADO == 1 ? <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {item.STOCK == 0 ? <>
                                                                <button type="button" className="btn btn-outline-dark" disabled onClick={() => handledAddProduct(item.ID)} ><Plus size='20' /></button>
                                                            </> : <>
                                                                <button type="button" className="btn btn-outline-dark" onClick={() => handledAddProduct(item.ID)} ><Plus size='20' /></button></>}
                                                        </td>
                                                    </tr>
                                                ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
