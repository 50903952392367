import React from 'react'
import { useEffect } from 'react';
import { Activity, AlertTriangle, DollarSign, Edit, Mail, Percent, Plus, RefreshCcw, Shield, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { loadDescuentoEdit, loadFormapagoEdit, startActDescuento, startActFormaPago, startDelDescuento, startDelFormaPago, startEditCredenciales, startEditGarantia, startEditTarifaAlm, startRegistroDescuento, startRegistroFormaPago, startloadCredenciales, startloadTarifaAlm } from '../../../../app/store/catalagos/thunks';
import { useForm } from 'react-hook-form';
import { EditMotodoPago } from '../modals/ToDo/EditMotodoPago';
import { EditDescuento } from '../modals/ToDo/EditDescuento';
export const Principales = ({ solo_num }) => {

  const dispatch = useDispatch();
  const { credenciales, descuentos, status, tarifaAlm, tipos_pagos } = useSelector(state => state.catalogos);
  const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);
  const handledUPDCredencialesCorreo = (data) => {

    // console.log(data);
    Swal.fire({
      title: 'Atención',
      text: "Está por Actualizar los datos del Envio de Correos ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startEditCredenciales(data))
      }
    })
  }

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      host: "",
      email: "",
      password: "",

    }
  });

  const { register: registerDesc, handleSubmit: handleSubmitDesc, reset: resetDesc, setValue: setValueDesc, formState: { errors: errorsDesc } } = useForm(
    { model: 'all' }
  );

  const { register: registerTarifa, handleSubmit: handleSubmitTarifa, reset: resetTarifa, setValue: setValueTarifa, formState: { errors: errorsTarifa } } = useForm(
    { model: 'all' }
  );

  const { register: registerTp, handleSubmit: handleSubmitTp, reset: resetTp, setValue: setValueTp, resetField: resetFieldTp, formState: { errors: errorsTp } } = useForm(
    { model: 'all' }
  );
  const { register: registerGarantia, handleSubmit: handleSubmitGarantia, reset: resetGarantia, setValue: setValueGarantia, formState: { errors: errorsGarantia } } = useForm(
    { model: 'all' }
  );

  useEffect(() => {
    dispatch(startloadCredenciales());
    dispatch(startloadTarifaAlm());
  }, [])

  useEffect(() => {
    if (credenciales.length != 0) {
      setValue('host', credenciales.HOST);
      setValue('email', credenciales.EMAIL);
      setValue('password', credenciales.PWD);
    }

    if (tarifaAlm.length != 0) {
      setValueTarifa('costoAlm', tarifaAlm.COSTO);
      setValueTarifa('diasGracia', tarifaAlm.DIAS_GRACIA);
      setValueTarifa('costoDiagnosticoI', tarifaAlm.DIAGNOSTICO_I);
      setValueTarifa('costoDiagnosticoT', tarifaAlm.DIAGNOSTICO_T);

      // garantia
      setValueGarantia('diasGarantia', tarifaAlm.DIAS_GARANTIA);
      setValueGarantia('conGarantia', tarifaAlm.CON_GARANTIA);
    }
  }, [status])


  const handleSaveDescuento = (data) => {
    resetDesc();
    dispatch(startRegistroDescuento(data, usuario));
  }


  const handledUPDTarifa = (data) => {
    // console.log(data);
    dispatch(startEditTarifaAlm(data));

  }

  const handledDelCat = (e) => {
    Swal.fire({
      title: 'Atención',
      text: "Está por DESACTIVAR un Descuento, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startDelDescuento(e));
      }
    })
  }

  const handledActCat = (e) => {
    Swal.fire({
      title: 'Atención',
      text: "Está por REACTIVAR un Descuento, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startActDescuento(e));
      }
    })
  }


  const handledDelTp = (e) => {
    Swal.fire({
      title: 'Atención',
      text: "Está por DESACTIVAR una Forma de Pago, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startDelFormaPago(e));
      }
    })
  }

  const handledActTp = (e) => {
    Swal.fire({
      title: 'Atención',
      text: "Está por REACTIVAR una Forma de Pago, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startActFormaPago(e));
      }
    })
  }

  const handleSaveTp = (data) => {
    // console.log(data);
    let categoria = data.TipoPago;
    let usuario_cap = usuario;
    dispatch(startRegistroFormaPago(categoria));
    resetFieldTp("TipoPago");
  }


  const handledUPDGarantia = (data) => {
    console.log(data);
    dispatch(startEditGarantia(data))
  }


  const handledEditMetodoPago = (e) => {
    // console.log(e);
    dispatch(loadFormapagoEdit(e))
  }

  const handledEditDescuento = (e) => {
    // console.log(e);
    dispatch(loadDescuentoEdit(e))
  }






  return (
    <>
      <div className="mb-3">
        <h1 className="h4 d-inline align-middle fw-bold">Principales</h1>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}>
                <div className="row mb-1">
                  <div className="col-12">
                    <h5 className="fw-bold me-2 border-0"><Mail size='22' /> Datos para el Envio de Correos: </h5>
                  </div>
                  <hr className="my-0 mb-3" />
                  <div className="col-12">
                    <div className="mb-2">
                      <label className="form-label">Host (Servidor):</label>
                      <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("host", { required: 'La direccion del Host es requerido.' })} />
                      {
                        errors.host &&
                        <div className='text-left-5 mt-2'>
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errors.host.message}</small></span>
                        </div>
                      }
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Email:</label>
                      <input type="mail" className="form-control" aria-label="First name" autoComplete='off' {...register("email", { required: 'Correo del Emisor es requerido.' })} />
                      {
                        errors.email &&
                        <div className='text-left-5 mt-2'>
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errors.email.message}</small></span>
                        </div>
                      }
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Contraseña:</label>
                      <input type="mail" className="form-control" aria-label="First name" autoComplete='off' {...register("password", { required: 'La Contraseña es requerida' })} />
                      {
                        errors.password &&
                        <div className='text-left-5 mt-2'>
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errors.password.message}</small></span>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-outline-primary ms-2" >
                      <RefreshCcw size='20' />  Actualizar
                    </button>
                  </div>

                </div>

              </form>

            </div>
          </div>
        </div>

        <EditDescuento />
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
          <div className="card">
            <div className="card-body">
              {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
              <div className="row mb-1">
                <div className="col-12">
                  <h5 className="fw-bold me-2 border-0"><Percent size='22' /> Descuentos disponibles: </h5>
                </div>
                <hr className="my-0 mb-3" />


                <form onSubmit={handleSubmitDesc(handleSaveDescuento)}>
                  <div className='row'>
                    <div className='col-7'>
                      <label className="form-label">Nombre:</label>
                      <input className="form-control me-2" type="text" autoComplete='off'
                        {...registerDesc('nombre', { required: 'Escribe el Nombre del Descuento', minLength: { value: 2, message: 'El Nombre del descuento debe tener 2 caracters' } })}
                      />
                    </div>
                    <div className='col-3'>
                      <label className="form-label">Valor:</label>
                      <input className="form-control me-2" type="text" autoComplete='off' onKeyDown={solo_num}
                        {...registerDesc('valor', { required: 'Escribe el  valor del descuento, Solo numeros sin decimales' })}
                      />
                    </div>

                    <div className='col-1 mt-4'>
                      <button type="submit" className="btn btn-outline-primary mt-1">  <Plus size="22" /> </button>
                      {/* <button type="submit" className="btn btn-outline-primary mt-1">  <Plus size="22" /> </button> */}
                    </div>
                    <div className='col-12'>
                      {
                        errorsDesc.nombre &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsDesc.nombre.message}</small></span>
                        </div>
                      }

                      {
                        errorsDesc.valor &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsDesc.valor.message}</small></span>
                        </div>
                      }
                    </div>
                  </div>
                </form>

                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                  <table className="table table-striped table-hover table-sm">
                    <thead className='th-sticky'>
                      <tr>
                        <th scope="col" className='th-sticky'>ID</th>
                        <th scope="col" className="th-sticky text-start">NOMBRE</th>
                        <th scope="col" className="th-sticky text-center">VALOR ($)</th>
                        <th scope="col" className="th-sticky text-center">ESTADO</th>
                        <th scope="col" className="th-sticky text-center">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        descuentos.length === 0 ?
                          <>
                            <tr>
                              <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                            </tr>
                          </> :
                          descuentos.map((item, index) => (
                            <tr key={index}>
                              <td scope="row">{item.ID}</td>
                              <td className="text-start">{item.NOMBRE}</td>
                              <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.VALOR)}</td>
                              <td className="text-center">
                                {
                                  item.ESTADO == 1 ?
                                    <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                }
                              </td>
                              <td className="text-center">
                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editDescuento" onClick={e => handledEditDescuento(item.ID)}>
                                  <Edit size="20" />
                                </button>
                                {
                                  item.ESTADO == 1 ? <>
                                    <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCat(item.ID)}>
                                      <Trash2 size="20" />
                                    </button></> :
                                    <>
                                      <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCat(item.ID)}>
                                        <RefreshCcw size="20" />
                                      </button>
                                    </>
                                }
                              </td>
                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmitTarifa(handledUPDTarifa)}>
                <div className="row mb-1">
                  <div className="col-12">
                    <h5 className="fw-bold me-2 border-0"><Shield size='22' /> Almacenamiento: </h5>
                  </div>
                  <hr className="my-0 mb-3" />
                  <div className="col-6">
                    <div className="mb-2">
                      <label className="form-label">Costo al dia:</label>
                      <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...registerTarifa('costoAlm', { required: 'Escribe el  valor de la tarifa, Solo numeros sin decimales' })} />
                      {
                        errorsTarifa.costoAlm &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsTarifa.costoAlm.message}</small></span>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="mb-2">
                      <label className="form-label">Diás de gracia:</label>
                      <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...registerTarifa('diasGracia', { required: 'Escribe el  valor de la tarifa, Solo numeros sin decimales' })} />
                      {
                        errorsTarifa.diasGracia &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsTarifa.diasGracia.message}</small></span>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <h5 className="fw-bold me-2 border-0"><Activity size='22' /> Dianostico: </h5>
                  </div>
                  <hr className="my-0 mb-3" />
                  <div className='col-6'>
                    <div className="mb-2">
                      <label className="form-label">Costo Inicial:</label>
                      <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...registerTarifa('costoDiagnosticoI', { required: 'Escribe costo' })} />
                      {
                        errorsTarifa.costoDiagnosticoI &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsTarifa.costoDiagnosticoI.message}</small></span>
                        </div>
                      }
                    </div>

                  </div>

                  <div className='col-6'>
                    <div className="mb-2">
                      <label className="form-label">Costo Final:</label>
                      <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}  {...registerTarifa('costoDiagnosticoT', { required: 'Escribe costo' })} />
                      {
                        errorsTarifa.costoDiagnosticoT &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errorsTarifa.costoDiagnosticoT.message}</small></span>
                        </div>
                      }
                    </div>

                  </div>

                  <div className="col-12 text-end">
                    <button type="submit" className="btn btn-outline-primary ms-2" >
                      <RefreshCcw size='20' />  Actualizar
                    </button>
                  </div>

                </div>

              </form>

            </div>
          </div>
        </div>

        <div className='col-12 mb-3'>
          <div className="accordion accordion-flush" id="accordionExample1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                  <DollarSign size='20' /> <span className='text-let-10 fw-bold'>  Metodos de pago</span>
                </button>
              </h2>
              <div id="collapseOne1" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                <div className="accordion-body">

                  <div className='row'>
                    {/* <div className='col-6'>

                      <form onSubmit={handleSubmitGarantia(handledUPDGarantia)}>
                        <div className="">
                          <div className="mb-2">
                            <label className="form-label">Dias de duración:</label>
                            <input type="text" className="form-control" aria-label="First name" onKeyDown={solo_num}  {...registerGarantia('diasGarantia', { required: 'Escribe dias garantía' })} />
                            {
                              errorsGarantia.diasGarantia &&
                              <div className='text-left-5 mt-1 '>
                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> *
                                <AlertTriangle size="18" color="#dc3545" />
                                <span className='text-left-10 fw-bold text-danger'><small>{errorsGarantia.diasGarantia.message}</small></span>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="">
                          <div className="mb-2">
                            <label className="form-label">Condiciones de la garantía:</label>
                            <textarea className="form-control" rows="6" {...registerGarantia('conGarantia', { required: 'Escribe los terminos de la garantia', maxLength: { value: 590, message: 'Los terminos debe contener máximo 590 caracteres' } })}></textarea>
                            {
                              errorsGarantia.conGarantia &&
                              <div className='text-left-5 mt-1 '>
                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> *
                                <AlertTriangle size="18" color="#dc3545" />
                                <span className='text-left-10 fw-bold text-danger'><small>{errorsGarantia.conGarantia.message}</small></span>
                              </div>
                            }
                          </div>
                        </div>
                        <div className=' text-end'>
                          <button type="submit" className="btn btn-outline-primary ms-2" >
                            <RefreshCcw size='20' />  Actualizar
                          </button>
                        </div>


                      </form>
                    </div> */}




                    <EditMotodoPago />
                    <div className='col-6'>
                      <h1 className="h5 mb-3 fw-bold">Métodos de pago</h1>

                      <form onSubmit={handleSubmitTp(handleSaveTp)}
                      >
                        <div className='row'>
                          <div className='col-10'>
                            <input className="form-control me-2" type="text" autoComplete='off'
                              {...registerTp('TipoPago', { required: 'Escribe Tipo de pago', minLength: { value: 3, message: 'Escribe Tipo de pago' } })}
                            />
                            {
                              errorsTp.TipoPago &&
                              <div className='text-left-5 mt-1 '>
                                <AlertTriangle size="18" color="#dc3545" />
                                <span className='text-left-10 fw-bold text-danger'><small>{errorsTp.TipoPago.message}</small></span>
                              </div>
                            }
                          </div>
                          <div className='col-1'>
                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                          </div>
                        </div>
                      </form>

                      <div className="table-responsive p-2 mt-2 table-scrollbar">
                        <table className="table table-striped table-hover table-sm">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col" className="text-start">NOMBRE</th>
                              <th scope="col" className="text-center">ESTADO</th>
                              <th scope="col" className="text-center">ACCIONES</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tipos_pagos.length === 0 ?
                                <>
                                  <tr>
                                    <td scope="row" colSpan={4}> No se han encontrado datos.</td>

                                  </tr>
                                </> :
                                tipos_pagos.map((item, index) => (
                                  <tr key={index}>
                                    <td scope="row">{item.ID}</td>
                                    <td className="text-start">{item.NOMBRE}</td>
                                    <td className="text-center">
                                      {
                                        item.ESTADO == 1 ?
                                          <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                      }
                                    </td>
                                    <td className="text-center">
                                      <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editMetodoPago" onClick={e => handledEditMetodoPago(item.ID)}>
                                        <Edit size="20" />
                                      </button>
                                      {
                                        item.ESTADO == 1 ? <>
                                          <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelTp(item.ID)}>
                                            <Trash2 size="20" />
                                          </button></> :
                                          <>
                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActTp(item.ID)}>
                                              <RefreshCcw size="20" />
                                            </button>
                                          </>
                                      }
                                    </td>
                                  </tr>
                                ))
                            }
                          </tbody>
                        </table>
                      </div>

                    </div>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>


      </div >
    </>
  )
}
