import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStoreCort, notprocessAction, processAction, saveIngreso, setComisiones, setComisiones_reg, setComisiones_rep, setCorte, setCortes_reg, setDescuentos, setEgresos, setError, setIndicadores, setIngresos } from "./slice";
// import { setIndicadores } from "../ordenes/slice";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const startRegistroIngreso = (data, capturante) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`Ingresos/postIngreso`, {
            capturo: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro,
            concepto: data.conceptoIngreso,
            descripcion: data.descripcionIngreso,
            detalle: data.detalleIngreso,
            monto: data.montoIngreso,
            forma_pago: data.tipoPago
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Ingreso registrado con exito'
                })
                dispatch(saveIngreso());
                // window.location.reload()

            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Ingreso... Intente de Nuevo'
            })
            dispatch(saveIngreso());
            // window.location.reload();

            return;
        });
        // dispatch(notprocessAction());
    }

}

export const startRegistroEgreso = (data, capturante) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`Ingresos/postEgreso`, {
            capturo: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro,
            concepto: data.conceptoIngreso,
            descripcion: data.descripcionIngreso,
            detalle: data.detalleIngreso,
            monto: data.montoIngreso,
            forma_pago: data.tipoPago
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Egreso registrado con exito'
                })
                dispatch(saveIngreso());
                // window.location.reload();

            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Egreso... Intente de Nuevo'
            })
            dispatch(saveIngreso());
            // window.location.reload();

            return;
        });
        // dispatch(notprocessAction());
    }

}

export const startloadIndicadores = () => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getIndicadoresDashboard`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setIndicadores({ indicadores: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadIngresos = () => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getIngresos30D`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setIngresos({ ingresos: response.data.data[0] }))
                dispatch(startloadIndicadores())
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadEgresos = () => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getEgresos30D`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setEgresos({ egresos: response.data.data[0] }))
                dispatch(startloadIndicadores())
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadDescuentos = () => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getDescuentos30D`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setDescuentos({ descuentos: response.data.data[0] }))
                dispatch(startloadIndicadores())
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadIngresosParam = (fechaInicial, fechaFinal) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getIngresosParam/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            // const listItems = response.data.data[0].INGRESOS;
            //  console.log(listItems);
            if (response.data.status == 200) {
                // console.log(response.data.data[0]);
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setIngresos({ ingresos: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
                Toast.fire({
                    icon: 'error',
                    title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
                })

                dispatch(setError());
                dispatch(startloadIndicadores())
                dispatch(startloadEgresos())
                dispatch(startloadDescuentos())
            }

        }).catch(function (error) {
            // console.log(error);


            Toast.fire({
                icon: 'error',
                title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
            })

            dispatch(setError());
            dispatch(startloadIndicadores())
            dispatch(startloadEgresos())
            dispatch(startloadDescuentos())
        });
    }
}

export const startloadEgresosParam = (fechaInicial, fechaFinal) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getEgresosParam/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setEgresos({ egresos: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                Toast.fire({
                    icon: 'error',
                    title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
                })
                // startloadIndicadores();
                dispatch(startloadIndicadores());
                dispatch(startloadIngresos());
                dispatch(startloadDescuentos());
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Toast.fire({
                icon: 'error',
                title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
            })
            // startloadIndicadores();
            dispatch(startloadIndicadores());
            dispatch(startloadIngresos());
            dispatch(startloadDescuentos());
            dispatch(setError())
            dispatch(setError())
        });
    }
}


export const startloadDescuentosParam = (fechaInicial, fechaFinal) => {

    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getDescuentosParam/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setDescuentos({ descuentos: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                Toast.fire({
                    icon: 'error',
                    title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
                })
                // startloadIndicadores();
                dispatch(startloadIndicadores())
                dispatch(startloadIngresos());
                dispatch(startloadEgresos());
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Toast.fire({
                icon: 'error',
                title: 'No se encontraron registros con los parámetros introducidos... Intente de Nuevo'
            })
            // startloadIndicadores();
            dispatch(startloadIndicadores());
            dispatch(startloadIngresos());
            dispatch(startloadEgresos());
            dispatch(setError())
        });
    }
}

export const startloadCortes = (fechaInicial, fechaFinal) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getCortes/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setCortes_reg({ cortes_reg: response.data.data }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar cortes registrados... Introduce parametros de busqueda diferentes',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}


export const startloadCorte = (fechaInicial, fechaFinal) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getCorte/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setCorte({ corte: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar datos para realizar el corte... Introduce parametros de busqueda diferentes',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}


export const startRegistroCorte = (capturante, FECHAI, FECHAF, acumulado, dec_efec, dec_trans, dec_tdd, dec_tdc, dec_total, fondo, dif) => {
    return async (dispatch, getState) => {
        // dispatch(processAction());

        // console.log(capturante, FECHAI, FECHAF, acumulado, dec_efec, dec_trans, dec_tdd, dec_tdc, dec_total, fondo, dif);

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`Ingresos/postCorte`, {
            capturo: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro,
            efectivo: dec_efec,
            trans: dec_trans,
            tdd: dec_tdd,
            tdc: dec_tdc,
            total_dec: dec_total,
            fondo: fondo,
            diff: dif,
            total_ing: acumulado,
            fechai: FECHAI,
            fechaf: FECHAF


        }).then(function (response) {

            // console.log(response.data);
            if (response.data.status = 200) {


                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Corte registrado con Exito',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());

                        // window.open(`./assets/reports/ordenEntrega.html?folio=${folio}`, '_blank');
                        window.open(`./assets/reports/reporteCorte.html?id=${response.data.last_id}`, '_blank');
                        // window.location.reload();
                    }
                });

            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Corte... Intente de Nuevo'
            })
            dispatch(saveIngreso());
            // window.location.reload();

            return;
        });
        dispatch(notprocessAction());
    }

}


export const startRegistroComision = (capturante, FECHAI, FECHAF, empleado, recepcionista, total) => {
    return async (dispatch, getState) => {
        // dispatch(processAction());

        // console.log(capturante, FECHAI, FECHAF, acumulado, dec_efec, dec_trans, dec_tdd, dec_tdc, dec_total, fondo, dif);

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`Ingresos/postComision`, {
            capturo: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro,
            fechai: FECHAI,
            fechaf: FECHAF,
            empleado: empleado,
            recepcionista: recepcionista,
            total: total

        }).then(function (response) {

            // console.log(response.data);
            if (response.data.status = 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Comisiones registradas con Exito',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());

                        // window.open(`./assets/reports/ordenEntrega.html?folio=${folio}`, '_blank');
                        window.open(`./assets/reports/reporteComision.html?id=${response.data.last_id}`, '_blank');
                        window.location.reload();
                    }
                });

            }
        }).catch(function (error) {
            console.log(error.response.data.msg)
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Comision... Intente de Nuevo'
            })



            Swal.fire({
                icon: 'error',
                title: 'Atencion',
                text: error.response.data.msg,
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    // console.log('Ejecutar Reload...');
                    // dispatch(clearStoreComision());
                    window.location.reload();
                    // window.open(`./assets/reports/ordenEntrega.html?folio=${folio}`, '_blank');

                }
            });








            // dispatch(saveIngreso());
            // window.location.reload();

            return;
        });
        dispatch(notprocessAction());
    }

}

export const startloadComisiones_rep = (usuario, fechaInicial, fechaFinal, recepcionista) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getComisiones/${usuario}/${fechaInicial}/${fechaFinal}/${recepcionista}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;

            if (response.data.status == 200) {
                // console.log(listItems);
                // console.log( response.data.data[0] );
                dispatch(setComisiones_rep({ comisiones_rep: listItems }));
                // dispatch(setCorte({ corte: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible encontrar datos para realizar el pago de comisiones... Introduce parametros de busqueda diferentes',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.msg,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}

export const startloadComisiones = (usuario, fechaInicial, fechaFinal, recepcionista) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getComisiones/${usuario}/${fechaInicial}/${fechaFinal}/${recepcionista}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;

            if (response.data.status == 200) {
                // console.log(listItems);
                // console.log( response.data.data[0] );
                dispatch(setComisiones({ comisiones: listItems }));
                // dispatch(setCorte({ corte: response.data.data[0] }))
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible encontrar datos para realizar el pago de comisiones... Introduce parametros de busqueda diferentes',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.msg,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}


export const startloadComisiones_f = (fechaInicial, fechaFinal) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.get(`Ingresos/getComisionesFront/${fechaInicial}/${fechaFinal}`, {

        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                // console.log( response.data.data[0] );
                // dispatch(setOrdenes({ ordenes: listItems }));
                // dispatch(setCorte({ corte: response.data.data[0] }))
                dispatch(setComisiones_reg({ comisiones_reg: listItems }));
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar datos para realizar el corte... Introduce parametros de busqueda diferentes',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}




// clearStoreCort


export const clearStoreCorts = () => {
    return (dispatch, getState) => {
        dispatch(clearStoreCort());
    }
}


export const clearStoreComision = () => {
    return (dispatch, getState) => {
        dispatch(clearStoreComision());
    }
}
