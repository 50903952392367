import React, { useRef } from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startEditRefaccion } from '../../../../app/store/ordenes/thunks';

export const M_EditRefaccion = () => {

    const dispatch = useDispatch();
    const closeModal = useRef(null);
    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            nombre: "",
        }
    });

    const { refaccion_edit_det,status, isLoading } = useSelector(state => state.ordenes);

    // console.log(refaccion_edit_det);
    // console.log(orden_edit[0].FOLIO);

    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }


    const clearForm = () => {
        reset();
    }

    const handleSave = (data) => {
        console.log(data);
        dispatch(startEditRefaccion(data,refaccion_edit_det.FOLIO_ID,refaccion_edit_det.ID))
        
    }

    if (status == 'ORDEN_ENCONTRADA') closeModal.current.click();


    return (
        <div className="modal fade" id="editRefaccionSol" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Editar Refaccion 
                            {/* {como_conocieron_edit.DESCRIPCION} */}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Refaccion:</label>
                                        <input type="text" className="form-control"  defaultValue={refaccion_edit_det.REFACCION} aria-label="First name" autoComplete='off' {...register("refaccion", { required: 'Nombre de Refaccion, es requerido' })} />
                                        {
                                            errors.refaccion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.refaccion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo HDL($):</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' defaultValue={refaccion_edit_det.COSTO_HDL} onKeyDown={solo_num}  {...register("costoHDL", { required: 'Costo refaccion, es requerido' })} />
                                        {
                                            errors.costoHDL &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoHDL.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo Cliente($):</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' defaultValue={refaccion_edit_det.COSTO_CLIENTE} onKeyDown={solo_num}  {...register("costoCliente", { required: 'Costo refaccion, es requerido' })} />
                                        {
                                            errors.costoCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Enlace:</label>
                                        <textarea className="form-control" rows="8" defaultValue={refaccion_edit_det.URL_REFA} {...register("enlace", { required: 'Enlace, para compra de refaccion' })}></textarea>
                                        {
                                            errors.enlace &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.enlace.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {/* <button type="button"  hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button> */}
                            <button type="button" ref={closeModal} className="btn btn-outline-danger" onClick={clearForm} data-bs-dismiss="modal"> <Slash size='20' />  Cancelar</button>
                            <button type="submit" className="btn btn-outline-primary"> <Save size='20' />  Guardar </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
