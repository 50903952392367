import React, { useState } from 'react'
import { Save, Slash, Mail, Phone, Award, User, AlertTriangle, Eye } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startChangeDataUser, startChangePWD } from '../../../app/store/usuarios/thunks';
export const Profile = () => {

  const solo_num = (e) => {
    if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
    else e.preventDefault();
}
  const { usuario, nombre, rol, puesto, avatar, email, telefono, celular, id_emp, id_acceso } = useSelector(state => state.usuario);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({ model: 'all' });
  const { register: registerPWD, reset: resetPWD, handleSubmit: handleSubmitPWD, resetField: resetFieldPWD, formState: { errors: errorsPWD } } = useForm({ model: 'all' });
  const dispatch = useDispatch();

  const clearUpdateData = () => {
    reset();
  }

  const handleUpdateData = (data) => {
    // console.log(data);
    dispatch(startChangeDataUser(data, id_acceso, id_emp));
    clearUpdateData();
  }

  const clearForm = () => {
    resetPWD();
  }

  const handleSavePWD = (dat) => {
    // console.log(dat);

    let pwd_nuevo = dat.nuevoPWD;
    let pwd_confirm = dat.confirmPWD;

    // console.log(pwd_nuevo, pwd_confirm);

    if (pwd_nuevo == pwd_confirm) {
      document.getElementById("alert_pwd").style.display = "none";

      dispatch(startChangePWD(dat, id_acceso));
      clearForm();
    }
    else {
      resetFieldPWD("nuevoPWD");
      resetFieldPWD("confirmPWD");
      document.getElementById("alert_pwd").style.display = "block";
    }


  }

  const [passwordShow, setpasswordShow] = useState(null);
  const togglePassword = () => {
    setpasswordShow(!passwordShow);
  };

  return (
    <>
      <div className="mb-3">
        <h1 className="h4 d-inline align-middle fw-bold">Mi perfil</h1>
      </div>

      <div className="row">
        <div className="col-md-5 col-xl-4">

          <div className="card mb-3">
            <div className="card-header">
              <h5 className="card-title mb-0">Detalles de perfil</h5>
            </div>
            <div className="card-body text-center mt-3">

              <img src={`./assets/images/avatars/${avatar}`} className='img-fluid rounded-circle mb-2' width="120" height="120" />
              <h4 className="mt-2 mb-1">{nombre}</h4>
              <div className="text-muted mb-2 text-uppercase"><span>{rol}</span> </div>
              <div className="text-muted mb-2 ">Usuario: <span>{usuario}</span></div>
            </div>

            <hr className="my-0" />
            <div className="card-body">
              <h5 className="h6 card-title">Sobre mí</h5>
              <ul className="list-unstyled mb-0">
                <li className="mb-2">
                  <Mail size="20" /> <span className='text-left-10'>{email}</span>
                </li>
                <li className="mb-2">
                  <Phone size="20" /> <span className='text-left-10'>{telefono}</span> y  <span className=''>{celular}</span>
                </li>
                <li className="mb-2">
                  <Award size="20" /> <span className='text-left-10 text-uppercase'>{puesto}</span>
                </li>

              </ul>
            </div>

          </div>
        </div>

        <div className="col-md-7 col-xl-8">
          <div className="card">
            <form onSubmit={handleSubmit(handleUpdateData)}>
              <div className="card-body">
                <h6 className="h6 card-title">Cambio de información</h6>
                <div className="px-1 mt-4">
                  <div className="mb-3">
                    <label className="form-label">Nombre:</label>
                    <input type="text" className="form-control" autoComplete='off' {...register("nombreEmpleado", { required: "Ingrese su nombre es requerido" })} />
                    {
                      errors.nombreEmpleado &&
                      <div className='text-left-5 mt-1 '>
                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreEmpleado.message}</small></span>
                      </div>
                    }
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Correo Electronico:</label>
                    <input type="email" className="form-control" autoComplete='off' {...register("emailEmpleado", {
                      required: "Ingrese su email es requerido",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Introduce una direccion de correo',
                      },
                    })} />

                    {
                      errors.emailEmpleado &&
                      <div className='text-left-5 mt-1 '>
                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errors.emailEmpleado.message}</small></span>
                      </div>
                    }
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="form-label">Telefono:</label>
                      <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("telefonoEmpleado")} />
                    </div>
                    <div className="col">
                      <label className="form-label">Celular:</label>
                      <input type="number" className="form-control" aria-label="Last name" autoComplete='off' onKeyDown={solo_num} {...register("celularEmpleado",
                        {
                          required: 'No. de Celular es requerido',
                          minLength: { value: 10, message: 'Debe ingresar 10 digitos solamente, sin guiones o cualquier otro caracter' },
                          maxLength: { value: 10, message: 'Debe ingresar 10 digitos solamente, sin guiones o cualquier otro caracter' },
                        })} />
                      {
                        errors.celularEmpleado &&
                        <div className='text-left-5 mt-1 '>
                          {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                          <AlertTriangle size="18" color="#dc3545" />
                          <span className='text-left-10 fw-bold text-danger'><small>{errors.celularEmpleado.message}</small></span>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="mt-3 text-end">
                    <button type="button" className="btn btn-outline-danger " onClick={clearUpdateData}>
                      <Slash size="20" /> Cancelar
                    </button>


                    <button type="submit" className="btn btn-outline-primary ml-1 text-left-15 ms-2">
                      <Save size="20" /> Guardar
                    </button>

                    {/* <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Guardando...
                  </button>  */}

                  </div>



                </div>
              </div>
            </form>


            <hr className="my-0" />
            <form onSubmit={handleSubmitPWD(handleSavePWD)}>
              <div className="card-body">
                <h6 className="h6 card-title">Cambio de contraseña</h6>
                <div className="px-1 mt-4">

                  <div className='mb-3' id='alert_pwd' style={{ display: "none" }}>
                    <div className="alert alert-danger" role="alert">
                      <AlertTriangle size="20" color="#dc3545" />  <span className='text-left-10 fw-bold'>Las nuevas contraseñas no coinciden, revisalas</span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Introduce tu contraseña actual:</label>
                    <input type={passwordShow ? "text" : "password"} className="form-control"
                      {...registerPWD('actualPWD', { required: 'La contraseña actual es requerida' })} />
                    {
                      errorsPWD.actualPWD &&
                      <div className='text-left-5 mt-1 '>
                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errorsPWD.actualPWD.message}</small></span>
                      </div>
                    }
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Nueva contraseña:</label>
                    <input type={passwordShow ? "text" : "password"} className="form-control"
                      {...registerPWD('nuevoPWD', {
                        required: 'La nueva contraseña es requerida',
                        minLength: { value: 8, message: 'Recuerda la contraseña debe tener mínimo 8 caracteres y un carácter especial' }
                      })}
                    />
                    {
                      errorsPWD.nuevoPWD &&
                      <div className='text-left-5 mt-1 '>
                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errorsPWD.nuevoPWD.message}</small></span>
                      </div>
                    }
                    {/* <div id="passwordHelpBlock" className="form-text">
                      Recuerda la contraseña debe tener mínimo 8 caracteres y un carácter especial.
                    </div> */}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Confirma la nueva contraseña:</label>
                    <input type={passwordShow ? "text" : "password"} className="form-control"
                      {...registerPWD('confirmPWD', {
                        required: 'La nueva contraseña es requerida',
                        minLength: { value: 8, message: 'Recuerda la contraseña debe tener mínimo 8 caracteres y un carácter especial' }
                      })}
                    />
                    {
                      errorsPWD.confirmPWD &&
                      <div className='text-left-5 mt-1 '>
                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errorsPWD.confirmPWD.message}</small></span>
                      </div>
                    }
                  </div>

                  <button type="button" className="btn btn-outline-dark" onClick={togglePassword}>Mostrar contraseñas</button>

                </div>

                <div className="mt-3 text-end">
                  <button type="button" className="btn btn-outline-danger">
                    <Slash size="20" /> Cancelar
                  </button>
                  <button type="submit" className="btn btn-outline-primary ms-2">
                    <Save size="20" /> Guardar
                  </button>
                </div>
              </div>
            </form>


          </div>
        </div>
      </div>
    </>
  )
}
