import React from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { startEditPuesto } from '../../../../../app/store/catalagos/thunks';

export const EditPuesto = () => {
    const { puesto_edit } = useSelector(state => state.catalogos);

    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            editnombrePuesto: "",
            editsalarioPuesto: "",
            editdescripcionPuesto: "",
        }
    });
    const { status, isLoading } = useSelector(state => state.catalogos);

    const closeModalEdit = useRef(null);
    const dispatch = useDispatch();

    const handleEditPuesto = (data) => {
        let id = puesto_edit.ID;
        dispatch(startEditPuesto(data, id))
    }

    const clearForm = () => {
        reset();
    }


    useEffect(() => {
        if (status == 'Puestos Obtenidos') {
            reset();
        }
    }, [status])

    if (status == 'Puestos Obtenidos') closeModalEdit.current.click();
    return (
        <div className="modal fade" id="editPuesto" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Editar Puesto: <span className='fw-bold'>{puesto_edit.NOMBRE}</span></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit(handleEditPuesto)}>
                        <div className="modal-body">

                            {
                                isLoading ? <>

                                    <div className='text-center'>
                                        <div className="spinner"></div>
                                    </div>

                                </> : <>
                                    <div className="row">
                                        {/* Show Data */}
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Nombre: <span className="badge bg-secondary small">Registrado</span></label>
                                                    <input type="text" className="form-control" aria-label="First name" value={puesto_edit.NOMBRE || ''} disabled />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Salario: </label>
                                                    <input type="text" className="form-control" aria-label="First name" value={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(puesto_edit.SALARIO)} disabled />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Descripción: </label>
                                                    <textarea className="form-control" rows="6" value={puesto_edit.DESCRIPCION || ''} disabled></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Edit Data */}
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label"> Nombre: <span className="badge bg-primary">Nuevo</span></label>
                                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("editnombrePuesto", { required: 'Nombre del puesto es requerido.', minLength: { value: 5, message: 'El nombre debe contener minimo 5 caracteres' } })} />
                                                    {
                                                        errors.editnombrePuesto &&
                                                        <div className='text-left-5 mt-2'>
                                                            <AlertTriangle size="18" color="#dc3545" />
                                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.editnombrePuesto.message}</small></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Salario: </label>
                                                    <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("editsalarioPuesto", { required: 'Salario es requerido.' })} autoComplete='off' />
                                                    {
                                                        errors.editsalarioPuesto &&
                                                        <div className='text-left-5 mt-2'>
                                                            <AlertTriangle size="18" color="#dc3545" />
                                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.editsalarioPuesto.message}</small></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Descripción: </label>
                                                    <textarea className="form-control" rows="6" autoComplete='off'
                                                        {...register('editdescripcionPuesto', {
                                                            required: 'La descripción del Puesto es requerido.',
                                                            minLength: { value: 10, message: 'La Descripción debe contener por los menos 10 caracteres' },
                                                            maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                                        })}
                                                    ></textarea>
                                                    {
                                                        errors.editdescripcionPuesto &&
                                                        <div className='text-left-20 mt-2'>
                                                            <AlertTriangle size="18" className='text-danger' />
                                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.editdescripcionPuesto.message}</small></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </>

                            }


                        </div>

                        <div className="modal-footer">
                            <button type="button" ref={closeModalEdit} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" /> Cancelar
                            </button>
                            {/*                         
                                    <>  <button className="btn btn-primary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Guardando...
                                    </button></> :
                                    <> */}
                            <button type="submit" className="btn btn-outline-primary"  >
                                <Save size="20" /> Guardar
                            </button>
                            {/* </> */}

                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
