import React from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { startEditServicio } from '../../../../../app/store/catalagos/thunks';
export const EditServicio = () => {

    const { servicio_edit } = useSelector(state => state.catalogos);
    const { status, isLoading, productosAlm } = useSelector(state => state.catalogos);
    const closeModalEdit = useRef(null);
    const dispatch = useDispatch();

    const solo_num = (e) => {
        if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106) || e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 13 || e.keyCode == 110 || e.keyCode == 190) return true;
        else e.preventDefault();
    }


    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            editnombreServicio: "",
            editcostoServicio: "",
            editcomisionRecepcionista: "",
            editcomisionTecnico: "",
            edittiempoDemoraServicio: "",
            editdescripcionServicio: "",
        }
    });

    const handleEditServicio = (data) => {
        let id = servicio_edit.ID;
        dispatch(startEditServicio(data, id))
    }

    const clearForm = () => {
        reset();
    }


    useEffect(() => {
        if (status == 'Servicios Obtenidos') {
            reset();
        }
    }, [status])

    if (status == 'Servicios Obtenidos') closeModalEdit.current.click();

    // let prod = "";
    // productosAlm.forEach(element => {
    //     if(element.ID == servicio_edit.PRODUCTO_ID){
    //         // console.log(element);
    //         prod = ;
            
          
    //     }
        
        
    // });

    // console.log(prod);
    // // document.getElementById('text_prod').value=prod;

    return (
        <div className="modal fade" id="editService" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Editar Servicio: <span className='fw-bold'>{servicio_edit.NOMBRE}</span> </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmit(handleEditServicio)}>
                        <div className="modal-body">


                            {
                                isLoading ? <>

                                    <div className='text-center'>
                                        <div className="spinner"></div>
                                    </div>

                                </> : <>
                                    <div className="row">
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>

                                            <div className='row'>

                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Servicio: <span className="badge bg-secondary small">Registrado</span></label>
                                                        <input type="text" className="form-control" aria-label="First name" value={servicio_edit.NOMBRE || ''} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Costo Serv: </label>
                                                        <input type="text" className="form-control" aria-label="First name" value={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(servicio_edit.COSTO_SERV)} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">C. Recepcionista:</label>
                                                        <input type="text" className="form-control" aria-label="First name" value={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(servicio_edit.COMISION_REP)} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">C. Tecnico:</label>
                                                        <input type="text" className="form-control" aria-label="First name" value={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(servicio_edit.COMISION_TEC)} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Tiempo p/ realizar:</label>
                                                        <input type="text" className="form-control" aria-label="First name" value={servicio_edit.HR_REALIZAR} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Descripción:</label>
                                                        <textarea className="form-control" rows="4" value={servicio_edit.DESCRIPCION} disabled></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Terminos de Garantia:</label>
                                                        <textarea className="form-control" rows="4" value={servicio_edit.GARANTIA} disabled></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="mb-2">
                                                        <label className="form-label">Dia Ga.:</label>
                                                        <input type="text" className="form-control" aria-label="First name" value={servicio_edit.DIAS_GARANTIA} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Producto:</label>
                                                        <input type="text" className="form-control" aria-label="First name" value={servicio_edit.DESCRIP_PROD+ ' - '+servicio_edit.MODELO_PROD}  disabled/>
                                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="mb-2">
                                                        <label className="form-label">Cant. Req:</label>
                                                        <input type="number" className="form-control" aria-label="First name" value={servicio_edit.CANTIDAD_PROD} disabled/>
                                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            <div className="row">

                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Servicio: <span className="badge bg-primary">Nuevo</span></label>
                                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("editnombreServicio", { required: 'Nombre del Servicio es requerido.', minLength: { value: 5, message: 'El nombre debe contener minimo 5 caracteres' } })} />
                                                        {
                                                            errors.editnombreServicio &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.editnombreServicio.message}</small></span>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Costo Serv:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} autoComplete='off' {...register("editcostoServicio", { required: 'Costo del Servicio es requerido.' })} />
                                                        {
                                                            errors.editcostoServicio &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.editcostoServicio.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Comision Recepcionista:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("editcomisionRecepcionista", { required: 'C. para la Recep. es requerido' })} />
                                                        {
                                                            errors.editcomisionRecepcionista &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.editcomisionRecepcionista.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Comision Tecnico:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("editcomisionTecnico", { required: 'C. para el Tecnico es requerido' })} />
                                                        {
                                                            errors.editcomisionTecnico &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.editcomisionTecnico.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Tiempo p/ realizar:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("edittiempoDemoraServicio", { required: 'Tiempo p/ realizar es requerido' })} />
                                                        {
                                                            errors.edittiempoDemoraServicio &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.edittiempoDemoraServicio.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Descripción:</label>
                                                        <textarea className="form-control" rows="3"
                                                            {...register('editdescripcionServicio', {
                                                                required: 'La descripción del Puesto es requerido.',
                                                                minLength: { value: 10, message: 'La Descripción debe contener por los menos 10 caracteres' },
                                                                maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                                            })}></textarea>
                                                        {
                                                            errors.editdescripcionServicio &&
                                                            <div className='text-left-20 mt-2'>
                                                                <AlertTriangle size="18" className='text-danger' />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.editdescripcionServicio.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Terminos de Garantia:</label>
                                                        <textarea className="form-control" rows="4"
                                                            {...register('garantiaServicio', {
                                                                required: 'Los Term. de garantia es requerido.',
                                                                minLength: { value: 10, message: 'Los Term. de Garantia deben contener por los menos 10 caracteres' },
                                                                // maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                                            })}></textarea>
                                                        {
                                                            errors.garantiaServicio &&
                                                            <div className='text-left-20 mt-2'>
                                                                <AlertTriangle size="18" className='text-danger' />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.garantiaServicio.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="mb-2">
                                                        <label className="form-label">Dias ga.:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("tiempoGarantiaServicio", { required: 'Dias de garantia es requerido' })} />
                                                        {
                                                            errors.tiempoGarantiaServicio &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.tiempoGarantiaServicio.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2">
                                                        <label className="form-label">Producto de Inventario:</label>
                                                        <select className="form-select" {...register("prodReqServicio")}>
                                                            <option value={'0'}>Seleccione producto</option>
                                                            {
                                                                productosAlm.map((item, index) => (
                                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' > {item.DESCRIPCION} - {item.MODELO}</option>
                                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                                ))
                                                            }
                                                        </select>
                                                        {/* <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("cantReqServicio", { required: 'Cantidad Requerida' })} /> */}
                                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="mb-2">
                                                        <label className="form-label">Cant. Req:</label>
                                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("cantReqServicio")} />
                                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                                    </div>
                                                </div>





                                            </div>
                                        </div>
                                    </div></>
                            }


                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModalEdit} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" />  Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary" >
                                <Save size="20" />  Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}
