import { createSlice } from '@reduxjs/toolkit';

export const facturacionSlice = createSlice({
    name: 'facturacionSlice',
    initialState: {
        status_facturacion: 'EN_ESPERA',
        facturacion: [],
        facturacion_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        loadingFacturacion: (state, action) => {
            state.isLoading = true;
            state.status_facturacion = 'CARGANDO_FACTURACION';
        },
        notloadingFacturacion: (state, action) => {
            state.isLoading = false;
            state.status_facturacion = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status_facturacion = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status_facturacion = 'EN_ESPERA';
        },
        setFacturacion: (state, action) => {
            state.isLoading = false;
            state.status_facturacion = "FACTURACION_CARGADOS";
            state.facturacion = action.payload.facturacion;
        },
        setFacturacion_Edit: (state, action) => {
            state.isLoading = false;
            state.status_facturacion = "FACTURACION_ENCONTRADO";
            state.facturacion_edit = action.payload.facturacion_edit;
        },
        setError: (state,action)=>{
            state.isLoading = false;
            state.status_facturacion = "FACTURACION_NO_ENCONTRADOS";
            state.facturacion = [];
            state.facturacion_edit = [];
        },
        clearStore: (state,action)=>{
            state.facturacion = [];
            state.facturacion_edit = [];
        }
    }
});
export const { loadingFacturacion,notloadingFacturacion,processAction,notprocessAction,setFacturacion,setFacturacion_Edit,setError,clearStore  } = facturacionSlice.actions;